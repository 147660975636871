<template>
  <b-card>
    <b-form @submit.prevent="updateNotification(domainId)">
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="outline-warning"
              to="/notifications"
              v-b-tooltip.hover
              title="Go Back"
            >
              <feather-icon icon="ArrowLeftCircleIcon" size="20" />
            </b-button>
          </b-col>
          <b-col cols="12" md="10">
            <h1>Edit the notification</h1>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Notification title" label-for="d-name">
              <b-form-input
                id="d-name"
                v-model="notification_title"
                placeholder="Notification title"
                autofocus
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Notification content" label-for="d-name">
              <b-form-input
                id="d-name"
                v-model="notification_content"
                placeholder="Notification content"
                autofocus
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!--select-->
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Account" label-for="d-name">
              <div>
                <b-form-select
                  @change="onAccChanged"
                  v-model="accSelected"
                  :options="accounts"
                />
                <!--  accSelected: <strong>{{ accSelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Type" label-for="d-name">
              <div>
                <v-select
                  v-model="typeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="types"
                  @input="typeselect"
                />
                <!-- typeSelected: <strong>{{ typeSelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="linkType" class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Notification link" label-for="d-name">
              <b-form-input
                id="d-name"
                v-model="notification_link"
                type="url"
                placeholder="https://www.example.com"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="App" label-for="d-name">
              <div>
                <b-form-select v-model="appSelected" :options="apps" />
                <!--  appSelected: <strong>{{ appSelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Day" label-for="d-name">
              <div>
                <b-form-select v-model="daySelected" :options="days" />
                <!--  daySelected: <strong>{{ daySelected }}</strong> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <!-- submit and reset -->
          <b-col>
            <!-- <b-col offset-md="4"> -->
            <b-button
              type="submit"
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="outline-warning"
              class="mr-1"
            >
              Update
            </b-button>
            <b-button
              type="reset"
              ref="resetButton"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
        <!-- <h1>{{ domainId }}</h1> -->
      </b-container>
    </b-form>
  </b-card>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import { BFormSelect } from "bootstrap-vue";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      typename: null,
      linkType: true,
      notification_content: null,
      notification_link: null,
      notification_title: null,
      // title,
      // appId,
      // selected: null,
      // was_sent: null,
      types: [],
      typeSelected: null,
      accSelected: null,
      appSelected: null,
      daySelected: null,
      account_name: null,
      account_link: null,
      // dayNames: [
      //   0,
      //   "Monday",
      //   "Tuesday",
      //   "Wednesday",
      //   "Thursday",
      //   "Friday",
      //   "Saturday",
      //   "Sunday",
      // ],
      // script_link: null,
      // data: {},
      // validation rules
      required,
      regex,
      url,
      accounts: [],
      apps: [],
      days: [
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
        { text: 4, value: 4 },
        { text: 5, value: 5 },
        { text: 6, value: 6 },
        { text: 7, value: 7 },
      ],
    };
  },
  computed: {
    domainId() {
      return this.$store.state.appsManager.notifID;
    },
    allNotifs() {
      return this.$store.state.appsManager.notifs;
    },
  },
  created() {
    this.appendNotif(this.notifID);
    this.getAllAccounts();
    this.getAllTypes();
  },
  methods: {
    typeselect() {
      if (this.typeSelected.text == "link") {
        this.linkType = true;
      } else {
        this.linkType = false;
      }
    },
    getAllTypes() {
      axios.get("/get-types").then(({ data }) => {
        data[0].forEach((el) => {
          this.types.push({ text: el.notif_type, value: el.id });
        });
      });
    },
    onAccChanged() {
      this.getAllApps(this.accSelected);
    },
    getAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then(({ data }) => {
          data.accounts.forEach((el) => {
            this.accounts.push({ text: el.account_name, value: el.id });
          });
          this.totalRows = this.accounts.length;
        })
        .catch(console.error);
      // this.getAllApps(this.accSelected);
    },
    getAllApps(accSelected) {
      this.apps.splice(0);
      axios
      .post(`/get-apps-by-account`, {
          accountId:accSelected,
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
      .then(({ data }) => {
        this.totalRows = this.apps.length;
        data.apps.forEach((el) => {
          this.apps.push({ text: el.title, value: el.id });
        });
      });
    },
    textOfType(value) {
      // if (value == 1) return "Simple";
      // if (value == 2) return "Link";
      if (value == 2) return "Simple";
      if (value == 3) return "Link";
    },
    getTypeName(value) {
      axios
        .post("get-type", {
          type_id: value,
        })
        .then(({ data }) => {
          this.typename = data[0][0].notif_type;
        });
    },
    appendNotif(id) {
      var notif_id = this.$store.state.appsManager.notifID;
      if (this.allNotifs.length > 0) {
        let items = this.allNotifs;
        if (this.domainId != undefined) {
          let _notif = items.find((o) => o.id == notif_id);
          this.notification_title = _notif.notification_title;
          this.notification_content = _notif.notification_content;
          this.accSelected = _notif.accSelected;
          this.appSelected = _notif.appSelected;
          this.daySelected = _notif.daySelected;
          this.notification_link = _notif.notification_link;
          this.typeSelected = {
            text: _notif.type,
            value: this.textOfType(_notif.type),
          };
          // this.was_sent = _notif.was_sent;
        }
      } else {
        var notif_id = this.$store.state.appsManager.notifID;
        axios
          .get("/showAllNotifications")
          .then(({ data }) => {
            if (notif_id != undefined) {
              let _notif = data[0].find((o) => o.id == notif_id);
              this.notification_title = _notif.notification_title;
              this.notification_content = _notif.notification_content;
              this.accSelected = _notif.account_id;
              this.appSelected = _notif.appId;
              this.getAllApps(_notif.account_id);
              this.daySelected = _notif.notif_day;
              this.notification_link = _notif.notification_link;
              // this.typeSelected.value = _notif.type;
              setTimeout(() => {
                this.getTypeName(_notif.type);
              }, 0);

              setTimeout(() => {
                this.typeSelected = {
                  text: this.typename,
                  value: _notif.type,
                };
                if (this.typename == "simple") {
                  this.linkType = false;
                } else {
                  this.linkType = true;
                }
              }, 1500);

              // this.getTypeName(3);
              // this.was_sent = _notif.was_sent;
              // this.script_link = _domain.script_link;
            }
          })
          .catch(console.error);
      }
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ImageInputChanged(e) {
      this.image = e.target.files[0];
    },
    async updateNotification(id) {
      if (this.linkType == false) {
        this.notification_link = null;
      }
      await axios
        .post("/updateNotification", {
          id: id,
          notification_title: this.notification_title,
          notification_content: this.notification_content,
          appId: this.appSelected,
          account_id: this.accSelected,
          notif_day: this.daySelected,
          type: this.typeSelected.value,
          notification_link: this.notification_link,
          // was_sent: this.was_sent,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            router.push({ name: "notifications" });
            return;
            // this.$refs.resetButton.click();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>